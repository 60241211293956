import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DATABASE_URL
});

export default {
  get: async (url: string, params?: any) => axiosInstance.get(url, { params }),
  post: async (url: string, params?: any, headers?: any) => axiosInstance.post(url, params, { headers: { ...headers } }),
};
