import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

type Props = {
  alt: string,
  id: number,
  imageUrl: string;
  title: string;
};

const PostTile: FC<Props> = ({
  alt, id, imageUrl, title
}) => {
  const navigate = useNavigate();
  const [mouseDown, setMouseDown] = useState<boolean>(true);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const eventControl = (event: React.MouseEvent<HTMLElement>) => {
    if (event.type === 'mousedown' || event.type === 'touchdown') {
      setMouseDown(true);
    }

    if (event.type === 'mousemove' || event.type === 'touchmove') {
      if (mouseDown) {
        setIsDragging(true);
      }
    }

    if (event.type === 'mouseout' || event.type === 'touchexit') {
      setMouseDown(false);
      setIsDragging(false);
    }

    if (event.type === 'mouseup' || event.type === 'touchend') {
      if (mouseDown && !isDragging) {
        navigate(`/post/${id}`);
      }
      setMouseDown(false);
      setTimeout(() => {
        setIsDragging(false);
      }, 100);
    }
  };

  return (
    <div
      className="postTile"
      onMouseDown={eventControl}
      onMouseMove={eventControl}
      onMouseOut={eventControl}
      onMouseUp={eventControl}
    >
      <img
        alt={alt}
        className="postTile-image"
        draggable="false"
        src={`${process.env.REACT_APP_CMS_API_HOST}${imageUrl}`}
      />
      <Typography variant="h6" className="postTile-title">
        {title}
      </Typography>
    </div>
  );
};

export default PostTile;
