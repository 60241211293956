import React, {
  useState, useMemo, useRef, useCallback
} from 'react';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axiosInstance from 'api/axios-instance';
import {
  Button,
  ButtonProps,
  Container,
  Snackbar,
  TextField,
  TextFieldProps
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import cardanoLogoLarge from 'assets/cardanoLogoLarge.png';
import ErrorMessage from './ErrorMessage';
import './getListed.scss';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const FormInput = styled(TextField)<TextFieldProps>(() => ({
  '&': {
    margin: '12px 0',
  },
  '& label': {
    color: 'white',
  },
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputBase-multiline': {
    height: '213px !important',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    height: '55px',
    '& fieldset': {
      borderColor: 'white',
    },
    '& input, textarea': {
      color: 'white',
      fontSize: '1.2em',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const FormButton = styled(Button)<ButtonProps>(() => ({
  '&': {
    margin: '12px 0',
    textTransform: 'none',
    color: '#2A36FF',
    backgroundColor: 'white',
  },
  '&:hover': {
    color: 'white',
  },
}));

interface formValues {
  assetLogo?: File | undefined;
  contactEmail: string;
  assetName: string;
  tokenPolicyId: string;
  message: string;
  recaptcha: string;
}

const GetListed = () => {
  const [inputKey, setInputKey] = useState<string>('');
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();
  const fileRef = useRef<HTMLInputElement>(null);

  const handleSuccessClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessToast(false);
  };

  const handleErrorClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorToast(false);
  };

  const initialValues: formValues = useMemo(
    () => ({
      assetLogo: undefined,
      assetName: '',
      contactEmail: '',
      message: '',
      recaptcha: '',
      tokenPolicyId: '',
    }),
    []
  );

  const validationSchema = yup.object({
    contactEmail: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    assetName: yup.string().required('Name of token is required'),
    tokenPolicyId: yup
      .string()
      .matches(/^[0-9aA-zZ\s]+$/, 'Unsupported characters')
      .min(56, 'Policy ID must have 56 characters')
      .max(56, 'Policy ID must have 56 characters')
      .required('Policy ID is required'),
    message: yup
      .string()
      .required('Message is required')
      .min(5, 'Message must have at least 5 characters'),
    assetLogo: yup
      .mixed()
      .required('Logo is required')
      .test(
        'image_format',
        'Unsupported file format',
        (file: File | undefined) => {
          if (!file || !file.type.includes('image')) {
            return false;
          }
          return true;
        }
      )
      .test('image_size', 'File is too large', (file: File | undefined) => {
        if (!file || file.size > 1048576) {
          return false;
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        const formData: FormData = new FormData();
        if (values.assetLogo) {
          formData.append('assetLogo', values.assetLogo);
        }
        formData.append('assetName', values.assetName);
        formData.append('contactEmail', values.contactEmail);
        formData.append('tokenPolicyId', values.tokenPolicyId);
        formData.append('message', values.message);
        formData.append('recaptcha', values.recaptcha);
        await axiosInstance.post('v1/market/requests', formData, {
          'Content-Type': 'multipart/form-data',
        });
        setSuccessToast(true);
        setSelectedImage(undefined);
        setInputKey(Math.random().toString(36));
        actions.resetForm();
      } catch (_e) {
        setErrorToast(true);
      }
    },
  });

  const handleRecaptcha = useCallback(
    (token: string) => {
      formik.setFieldValue('recaptcha', token);
    },
    [inputKey] // eslint-disable-line
  );

  return (
    <div id="getListed" className="getListed">
      <img
        alt="cardano-logo"
        className="gl-cardano-logo"
        src={cardanoLogoLarge}
      />
      <Container className="gl-container">
        <div className="gl-title">Get listed on Cardano Market</div>
        <div className="gl-subtitle">
          Fill in the form with token information
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="gl-row">
            <div className="gl-form-column">
              <div className="gl-input">
                <FormInput
                  autoComplete="off"
                  fullWidth
                  label="Contact email"
                  name="contactEmail"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.contactEmail}
                  variant="outlined"
                />
                <ErrorMessage
                  message={formik.errors.contactEmail}
                  touched={formik.touched.contactEmail}
                />
              </div>
              <div className="gl-input">
                <FormInput
                  autoComplete="off"
                  fullWidth
                  label="Asset name"
                  name="assetName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.assetName}
                  variant="outlined"
                />
                <ErrorMessage
                  message={formik.errors.assetName}
                  touched={formik.touched.assetName}
                />
              </div>
              <div className="gl-input">
                <FormInput
                  autoComplete="off"
                  fullWidth
                  label="Token policyID"
                  name="tokenPolicyId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.tokenPolicyId}
                  variant="outlined"
                />
                <ErrorMessage
                  message={formik.errors.tokenPolicyId}
                  touched={formik.touched.tokenPolicyId}
                />
              </div>
            </div>
            <div className="gl-form-column">
              <div className="gl-input gl-message">
                <FormInput
                  autoComplete="off"
                  fullWidth
                  label="Message"
                  multiline
                  name="message"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  rows={8}
                  type="text"
                  value={formik.values.message}
                  variant="outlined"
                />
                <ErrorMessage
                  message={formik.errors.message}
                  touched={formik.touched.message}
                />
              </div>
            </div>
          </div>
          <input
            key={inputKey || ''}
            type="file"
            ref={fileRef}
            name="assetLogo"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.currentTarget.files) {
                setSelectedImage(e.currentTarget.files[0]);
                formik.setFieldValue('assetLogo', e.currentTarget.files[0]);
              }
            }}
          />
          <div className="gl-row">
            <div className="gl-actions">
              <FormButton
                onClick={() => {
                  if (selectedImage) {
                    setSelectedImage(undefined);
                    setInputKey(Math.random().toString(36));
                    formik.setFieldValue('assetLogo', undefined);
                  } else {
                    fileRef.current?.click();
                  }
                }}
                variant="contained"
                endIcon={<DownloadIcon />}
              >
                {selectedImage ? 'Remove' : 'Upload logo'}
              </FormButton>
              <FormButton
                className="gl-submitButton"
                type="submit"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Send
              </FormButton>
              {process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY && (
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY}
                >
                  <GoogleReCaptcha onVerify={handleRecaptcha} />
                </GoogleReCaptchaProvider>
              )}
            </div>
            <div className="gl-actions">
              <div className="gl-input gl-input-image">
                {selectedImage && (
                  <img
                    alt="not found"
                    height="100px"
                    src={URL.createObjectURL(selectedImage)}
                    width="100px"
                  />
                )}
                <ErrorMessage
                  message={formik.errors.assetLogo}
                  touched={formik.touched.assetLogo}
                />
              </div>
            </div>
          </div>
        </form>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={successToast}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Token information sent!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={errorToast}
        autoHideDuration={4000}
        onClose={handleErrorClose}
      >
        <Alert onClose={handleErrorClose} severity="error">
          Something went wrong.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GetListed;
