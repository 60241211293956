import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from 'api/axios-instance';

import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TokenPrice } from 'types/TokenPrice';
import { TokenMetadata } from 'types/TokenMeta';
import { MarketMetadata } from 'types/MarketMeta';
import CenteredProgress from 'components/centeredProgress/CenteredProgress';
import PriceText from 'components/table/PriceText';
import ExchangeCard from 'components/show/ExchangeCard';
import PlaceholderIcon from 'assets/TokenPlaceholderIcon.svg';
import './ShowToken.scss';

type Props = {
  assetName: string;
  policyId: string;
};

const ShowToken = ({ assetName, policyId }: Props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [tokenPrice, setTokenPrice] = useState<TokenPrice>();
  const [tokenMeta, setTokenMeta] = useState<TokenMetadata>();
  const [marketMeta, setMarketMeta] = useState<MarketMetadata>();

  const fetchTokenPrice = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/v1/token/price?policyId=${policyId}&assetName=${assetName}`);
      setTokenPrice(data);
    } catch (e) {
      // TODO error handling
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [policyId, assetName]);

  const fetchTokenMeta = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/v1/token/metadata?policyId=${policyId}&assetName=${assetName}`);
      setTokenMeta(data);
    } catch (e) {
      // TODO error handling
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [policyId, assetName]);

  const fetchMarketMeta = async () => {
    try {
      const { data } = await axiosInstance.get('/v1/market/metadata');
      setMarketMeta(data);
    } catch (e) {
      // TODO error handling
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenPrice();
    fetchTokenMeta();
    fetchMarketMeta();
  }, [fetchTokenPrice, fetchTokenMeta]);

  return (
    <Box className="modal" sx={{ backgroundColor: 'background.default' }}>
      <Button
        variant="text"
        sx={{
          marginBottom: '9px',
        }}
        onClick={() => navigate('/')}
        startIcon={<ArrowBackIcon fontSize="small" />}
      >
        Go back
      </Button>
      {loading && (
        <div className="showToken-loading">
          <CenteredProgress />
        </div>
      )}
      {tokenPrice && marketMeta && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img
              src={
                tokenMeta?.logo
                  ? `data:image/png;base64,${tokenMeta.logo}`
                  : PlaceholderIcon
              }
              alt="logo"
              height="50"
            />
            <Typography
              variant="h4"
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '12px',
              }}
            >
              {decodeURIComponent(assetName)}
            </Typography>
          </Box>
          {tokenMeta && tokenMeta.description}
          <PriceText
            price={tokenPrice.price}
            change24={tokenPrice.change24}
            withChange24
          />
          <Box sx={{ width: '100%' }}>
            <Typography
              variant="h6"
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              Cardano Exchanges:
              {tokenPrice.markets.map((market) => (
                <ExchangeCard
                  key={market.market}
                  market={market}
                  metadata={marketMeta[market.market as keyof MarketMetadata]}
                />
              ))}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShowToken;
