import { useEffect, useState } from 'react';
import { Token } from 'types/Token';
import axiosInstance from 'api/axios-instance';

const useFetchTokens = () => {
  const [data, setData] = useState<Token[]>([]);
  const [error, setError] = useState<any|undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);

  useEffect(() => {
    (
      async () => {
        if (reload) {
          try {
            setLoading(true);
            const { data }: { data: Token[] } = await axiosInstance.get('/v1/token/price/list');
            setData(data);
            setError(undefined);
          } catch (e: any) {
            setError(e);
          } finally {
            setLoading(false);
            setReload(false);
          }
        }
      }
    )();
  }, [reload]);

  useEffect(() => {
    const interval = setInterval(() => setReload(true), (15 * (60 * 1000)));
    return () => clearInterval(interval);
  }, []);

  return { data, error, loading };
};

export default useFetchTokens;
