import React, { FC } from 'react';

import {
  TableHead, TableRow, TableCell, TableSortLabel,
} from '@mui/material';

import { Token } from 'types/Token';
import { Header } from 'types/Header';

import { SortOrder } from './sort';

interface Props {
  order: SortOrder;
  orderBy: keyof Token;
  handleSort: (property: keyof Token) => void;
}
const TableHeader: FC<Props> = (props) => {
  const { order, orderBy, handleSort } = props;

  const createSortHandler = (property: keyof Token) => () => {
    handleSort(property);
  };

  const headers: Header[] = [
    {
      property: 'assetName',
      name: 'Name',
    },
    {
      property: 'price',
      name: 'Price (ADA)',
    },
    {
      property: 'change24',
      name: '24H Change',
    },
    {
      property: 'marketCap',
      name: 'Fully Diluted Market Cap (ADA)',
    },
  ];

  return (
    <TableHead>
      <TableRow sx={{ '&:last-of-type th': { width: '25%' } }}>
        {headers.map((header) => (
          <TableCell
            key={header.property}
            sortDirection={orderBy === header.property ? order : false}
          >
            <TableSortLabel
              active={orderBy === header.property}
              direction={orderBy === header.property ? order : 'desc'}
              onClick={createSortHandler(header.property)}
            >
              {header.name}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
