import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, CssBaseline, Container, Modal, Typography
} from '@mui/material';
import ArticleDisplay from 'components/articleDisplay/ArticleDisplay';
import Footer from 'components/footer/Footer';
import GetListed from 'components/getListed/GetListed';
import Info from 'components/info/Info';
import RecentPostsBar from 'components/recentPostsBar/RecentPostsBar';
import Navbar from 'components/navbar/Navbar';
import ShowToken from 'components/show/ShowToken';
import Table from 'components/table/Table';
import { ThemeProvider } from 'hooks/useTheme';

const App: FC = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  return (
    <ThemeProvider>
      <CssBaseline />
      <Navbar />
      <Container className="appContainer">
        <Typography
          sx={{
            fontWeight: 'bold',
            marginBottom: '32px',
            textAlign: 'left',
          }}
          variant="h4"
        >
          Discover the world of
          <span className="cardanoName"> Cardano </span>
          and&nbsp;its&nbsp;tokens
        </Typography>
        <RecentPostsBar />
        {pathname.includes('/post/') ? (
          <ArticleDisplay
            id={pathname.split('/')[2]}
          />
        ) : (
          <>
            <Box sx={{ margin: '24px 0 16px' }}>
              <Typography fontWeight="bold" variant="h4">
                <span className="cardanoName">Cardano </span>
                native token prices
              </Typography>
            </Box>
            <Table />
          </>
        )}
        <Modal
          open={pathname.includes('/token/')}
          onClose={() => navigate('/')}
        >
          <>
            <ShowToken
              assetName={pathname.split('/')[3]}
              policyId={pathname.split('/')[2]}
            />
          </>
        </Modal>
        <Info />
      </Container>
      <GetListed />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
