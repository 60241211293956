import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useTheme,
  useMediaQuery,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import { Token } from 'types/Token';
import PriceText from 'components/table/PriceText';
import PlaceholderIcon from 'assets/TokenPlaceholderIcon.svg';

import { dataSort, SortOrder } from './sort';

interface Props {
  order: SortOrder;
  orderBy: keyof Token;
  rowData: Token[];
  tokenNumber?: number;
}
const BasicTableBody: FC<Props> = ({ ...props }) => {
  const {
    order,
    orderBy,
    rowData,
    tokenNumber = 10,
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableBody>
      {rowData
        && dataSort(rowData, order, orderBy, tokenNumber).map((token) => {
          const tokenImage = `${process.env.REACT_APP_DATABASE_URL}/images/${token.subject}`;

          return (
            <TableRow
              hover
              key={`${token.policyId}/${token.assetName}`}
              onClick={() => navigate(`/token/${token.policyId}/${encodeURIComponent(token.assetName)}`)}
              sx={{
                backgroundColor: 'background.default',
                '&:nth-of-type(odd)': {
                  backgroundColor:
                    theme.palette.mode !== 'dark' ? '#F2F5FD' : '#1e1e24',
                },
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <TableCell component="th" scope="row">
                <div
                  style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
                >
                  <img
                    alt="logo"
                    onError={({ currentTarget }) => {
                      currentTarget.src = PlaceholderIcon; // eslint-disable-line no-param-reassign
                    }}
                    src={tokenImage}
                    width="30"
                  />
                  {token.ticker && isMobile ? token.ticker : token.assetName}
                </div>
              </TableCell>
              <TableCell style={{ minWidth: '150px' }}>
                <PriceText price={token.price} />
              </TableCell>
              <TableCell
                style={{ color: token.change24 >= 0 ? 'green' : 'red' }}
              >
                {`${parseFloat((token.change24 * 100).toFixed(2))}%`}
              </TableCell>
              <TableCell>
                {`${parseFloat(token.marketCap.toFixed()).toLocaleString(
                  'en'
                )} ₳`}
              </TableCell>
            </TableRow>
          );
        })}
    </TableBody>
  );
};

export default BasicTableBody;
