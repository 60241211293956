import React, { FC } from 'react';
import BigNumber from 'bignumber.js';

import { Typography } from '@mui/material';

type PriceTextProps = {
  price: number;
  change24?: number;
  withChange24?: boolean;
};
const MAX_NUMB_AFTER_COMA = 14;
const DESIRED_PREC = 6;

export const PriceText: FC<PriceTextProps> = ({ ...props }) => {
  const { price, change24, withChange24 } = props;

  const formatText = (value: number) => {
    if (new BigNumber(value).comparedTo(999999) === 1) {
      return value.toFixed(0);
    }

    const str = new BigNumber(value.toPrecision(6)).toFixed();
    const numbLength = str.length - 2; // remove “0.0”
    const predPrediction = DESIRED_PREC - numbLength - MAX_NUMB_AFTER_COMA;

    if (numbLength <= MAX_NUMB_AFTER_COMA) return str;
    if (predPrediction > 0) {
      new BigNumber(value.toPrecision(predPrediction)).toFixed();
    } else {
      return value.toFixed(MAX_NUMB_AFTER_COMA);
    }
  };

  return withChange24 && change24 !== undefined ? (
    <Typography variant="h5" style={{ display: 'flex', gap: '15px' }}>
      {`${formatText(price)} ₳`}
      <span style={{ color: change24 >= 0 ? 'green' : 'red' }}>
        {`${parseFloat((change24 * 100).toFixed(2))}%`}
      </span>
    </Typography>
  ) : (
    <Typography variant="body2">{`${formatText(price)} ₳`}</Typography>
  );
};

export default PriceText;
