import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';

import logoCardanoMarket from 'assets/logoCardanoMarket.png';
import logoCardanoMarketWhite from 'assets/logoCardanoMarketWhite.png';
import { useTheme } from 'hooks/useTheme';
import useMobile from 'hooks/useMobile';
import './Navbar.scss';

const Navbar = () => {
  const { mode, toggleColorMode } = useTheme();
  const isMobile = useMobile();

  return (
    <Container className="navbar-container">
      <Toolbar className="navbar-toolbar">
        <Link to="/">
          <img src={mode === 'dark' ? logoCardanoMarketWhite : logoCardanoMarket} alt="logo" className="navbar-logo" />
        </Link>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            paddingRight: '24px',
            width: 'fit-content',
          }}
        >
          {!isMobile && (
            <>
              <Button
                color="secondary"
                href="/#coinsTable"
                sx={{ textTransform: 'none' }}
                variant="text"
              >
                Coins
              </Button>
              <Button
                color="secondary"
                href="#info"
                sx={{ textTransform: 'none' }}
                variant="text"
              >
                About
              </Button>
            </>
          )}
          <IconButton sx={{ ml: 1 }} onClick={() => toggleColorMode()}>
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {!isMobile && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mx: 1 }}
            />
          )}
          <Button
            href="#getListed"
            endIcon={<ArrowForwardIcon />}
            sx={{ textTransform: 'none' }}
          >
            Get listed
          </Button>
        </Box>
      </Toolbar>
    </Container>
  );
};

export default Navbar;
