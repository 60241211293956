import axios from 'axios';

const cmsAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL,
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_CMS_API_TOKEN}`,
  },
});

export default {
  get: async (url: string, params?: any) => cmsAxiosInstance.get(url, { params }),
  post: async (url: string, params?: any, headers?: any) => cmsAxiosInstance.post(url, { ...params }, { headers: { ...headers } }),
};
