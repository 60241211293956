import React, { FC, useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import cmsAxiosInstance from 'api/cms-axios-instance';
import CenteredProgress from '../centeredProgress/CenteredProgress';
import PostTile from './PostTile';
import 'react-multi-carousel/lib/styles.css';
import './recentPostsBar.scss';

const MAX_RECENTS = 12;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

interface Article {
  id: number,
  attributes: {
    image: any,
    title: string,
  }
}

const RecentPostsBar: FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchArticles = async () => {
    setLoading(true);
    const { data: { data } } = await cmsAxiosInstance.get(`/articles?populate=*&?fields[0]=image&fields[1]=title&sort[0]=id:desc&pagination[start]=0&pagination[limit]=${MAX_RECENTS}`);
    const arr = [...data].reverse();
    setArticles(arr);
    setLoading(false);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="bar-loading">
        <CenteredProgress />
      </div>
    );
  }

  return (
    <Carousel
      arrows={false}
      autoPlay
      autoPlaySpeed={5000}
      infinite
      removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
      responsive={responsive}
      showDots
      swipeable
      transitionDuration={1000}
      renderDotsOutside
      dotListClass="dots"
    >
      {articles.map((article) => {
        const {
          image,
          title,
        } = article.attributes;

        const {
          alternativeText,
          url,
        } = image.data.attributes;

        return (
          <PostTile
            alt={alternativeText}
            id={article.id}
            imageUrl={url}
            key={article.id}
            title={title}
          />
        );
      })}
    </Carousel>
  );
};

export default RecentPostsBar;
