import { Token } from 'types/Token';

export type SortOrder = 'asc' | 'desc';

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = <Key extends keyof Token>(order: SortOrder, orderBy: Key): (recordA: Token, recordB: Token) =>
  number => (order === 'desc'
    ? (recordA, recordB) => descendingComparator(recordA, recordB, orderBy)
    : (recordA, recordB) => -descendingComparator(recordA, recordB, orderBy));

export const dataSort = (data: Token[], order: SortOrder, orderBy: keyof Token, tokenNumber: number) => data.slice().sort(getComparator(order, orderBy)).slice(0, tokenNumber);
