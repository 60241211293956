import React, { FC, useState, useMemo } from 'react';
import CenteredProgress from 'components/centeredProgress/CenteredProgress';
import {
  Box, InputAdornment, TableContainer, Table, TextField, Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Token } from 'types/Token';
import TableHeader from 'components/table/TableHeader';
import TableBody from 'components/table/TableBody';
import RefreshCounter from 'components/table/RefreshCounter';
import useFetchTokens from 'hooks/useFetchTokens';
import { SortOrder } from './sort';
import './Table.scss';

const BasicTable: FC = () => {
  const [order, setOrder] = useState<SortOrder>('desc');
  const [orderBy, setOrderBy] = useState<keyof Token>('marketCap');
  const [search, setSearch] = useState<string>('');
  const [tokenNumber, setTokenNumber] = useState<number>(10);

  const { data: tokens = [], error, loading } = useFetchTokens();

  const handleSort = (property: keyof Token) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const filteredTokens = useMemo(() => {
    if (!tokens) {
      return [];
    } if (search.length > 0) {
      const searchTokens = tokens.filter((t) => t.assetName.toLowerCase().includes(search.toLowerCase()));
      setTokenNumber(searchTokens.length);
      return searchTokens;
    }
    return tokens;
  }, [search, tokens]);

  return (
    <>
      {!loading && (
        <div className="headRow">
          <RefreshCounter error={error} time={tokens[0]?.time} />
          <TextField
            id="search-input"
            label="Search token"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              if (search.length > 0 && e.target.value.length === 0) {
                setTokenNumber(10);
              }
              setSearch(e.target.value);
            }}
            size="small"
            value={search}
            variant="outlined"
          />
        </div>
      )}
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              minHeight: '100px',
            }}
          >
            <CenteredProgress />
          </Box>
        ) : !error && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHeader order={order} orderBy={orderBy} handleSort={handleSort} />
            <TableBody
              order={order}
              orderBy={orderBy}
              rowData={filteredTokens}
              tokenNumber={tokenNumber}
            />
          </Table>
        )}
      </TableContainer>
      {tokenNumber < filteredTokens.length && (
        <div
          className="loadMore"
          onClick={() => {
            setTokenNumber(tokenNumber + 10);
          }}
        >
          Load more...
        </div>
      )}
    </>
  );
};

export default BasicTable;
