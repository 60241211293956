import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import useFetchArticle from 'hooks/useFetchArticle';
import { Box, Typography } from '@mui/material';
import CenteredProgress from '../centeredProgress/CenteredProgress';
import './articleDisplay.scss';

type Props = {
  id: string;
};

const ArticleDisplay = ({ id }: Props) => {
  const { data, error, loading } = useFetchArticle(id);

  const articleImage = useCallback((props:any) => <img alt="articleImage" className="articleImage" {...props} />, []);

  const renderArticle = useCallback(() => {
    if (data) {
      return (
        <Box flex={1} mt={4}>
          <Typography variant="h5">{data.title}</Typography>
          <img
            alt={data.image.data.attributes.alternativeText}
            className="image"
            src={`${process.env.REACT_APP_CMS_API_HOST}${data.image.data.attributes.url}`}
          />
          <div className="content">
            <ReactMarkdown
              components={{ img: ({ node, ...props }) => articleImage(props) }}
              transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.REACT_APP_CMS_API_HOST}${uri}`)}
            >
              {data.content as string}
            </ReactMarkdown>
          </div>
        </Box>
      );
    }
    if (error) {
      return (
        <Box mt={4}>
          <Typography variant="body1">We&apos;re sorry, there was a problem with loading this article&apos;s content.</Typography>
        </Box>
      );
    }

    return null;
  }, [data, error]);

  if (loading) {
    return (
      <Box mt={4} className={classnames('articleDisplay', 'loading')}>
        <CenteredProgress />
      </Box>
    );
  }

  return renderArticle();
};

export default ArticleDisplay;
