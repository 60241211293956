import React from 'react';

type Props = {
  message?: string,
  touched?: boolean,
};

const ErrorMessage = ({ message, touched }: Props) => {
  if (!message || !touched) {
    return null;
  }

  return (
    <div className="error-message">
      {message}
    </div>
  );
};

export default ErrorMessage;
