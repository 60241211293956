import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  responsiveFontSizes,
} from '@mui/material';

type ThemeContextProps = {
  mode: 'dark' | 'light';
  toggleColorMode: Function;
};

type ThemeProviderProps = {
  children: React.ReactNode;
};

const defaultProps: ThemeContextProps = {
  mode: 'dark',
  toggleColorMode: () => {},
};

const ThemeContext = createContext<ThemeContextProps>(defaultProps);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [mode, setMode] = useState<'dark' | 'light'>('dark');

  const toggleColorMode = useCallback(() => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    localStorage.setItem(
      'colorScheme',
      JSON.stringify(mode === 'light' ? 'dark' : 'light')
    );
  }, [mode]);

  const theme = React.useMemo(
    () => responsiveFontSizes(
      createTheme({
        typography: {
          fontFamily: [
            'Mulish',
            'sans-serif',
          ].join(','),
        },
        palette: {
          mode,
          primary: { main: '#225AFF' },
          ...(mode === 'light'
            ? {
              secondary: { main: '#000637' },
            }
            : {
              secondary: { main: '#E0F1F1' },
              background: {
                default: '#17171d',
              },
            }),
        },
      })
    ),
    [mode]
  );

  const value = useMemo((): ThemeContextProps => ({ mode, toggleColorMode }), [mode, toggleColorMode]);

  useEffect((): void => {
    const colorScheme = localStorage.getItem('colorScheme');
    if (colorScheme !== null) {
      setMode(JSON.parse(colorScheme));
    }
  }, []);

  return (
    <ThemeContext.Provider value={value}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
