import React, { FC } from 'react';

import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { useTheme } from 'hooks/useTheme';
import logoCardanoMarket from 'assets/logoCardanoMarket.png';
import logoCardanoMarketWhite from 'assets/logoCardanoMarketWhite.png';
import './Footer.scss';

const Footer: FC = () => {
  const { mode } = useTheme();

  return (
    <div className="footer">
      <Container>
        <div className="footer-container">
          <img src={mode === 'dark' ? logoCardanoMarketWhite : logoCardanoMarket} alt="logo" className="footer-logo" />
          <div className="footer-column">
            <Box
              sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }}
            >
              <Button color="secondary">Privacy Policy</Button>
              <Button color="secondary">Terms & Conditions</Button>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ mx: 2 }}
              />
              <Button
                color="primary"
                onClick={() => {
                  window.open('https://byron.network/#/', '_blank');
                }}
              >
                Byron Network
              </Button>
            </Box>
            <div className="footer-rights">
              Copyright@ 2022 Byron Network OU. All rights reserved.
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
