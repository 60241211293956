import React from 'react';

import { Typography } from '@mui/material';

import './Info.scss';

const Info = () => (
  <div id="info" className="info">
    <Typography color="primary" letterSpacing={4} variant="button">
      CARDANO TOKEN ASSET TRACKER
    </Typography>
    <Typography my={3} variant="h4">
      Cardano Market
    </Typography>
    <Typography variant="body1">
      Cardano Market is an asset tracker and price aggregator that displays current
      prices estimations based on results from different decentralized exchanges
      on Cardano Blockchain. Price indicated on the Cardano Market is and
      average from data extracted from SundaeSwap, MuesliSwap and MinSwap that
      is updated every few minutes.
    </Typography>
  </div>
);

export default Info;
