import React, { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { formatTimeStamp } from '../../utils/timestamp-utils';

interface Props {
  error?: any;
  time: string;
}
const RefreshCounter: FC<Props> = ({ ...props }) => {
  const { error, time } = props;

  const parsedTime = useMemo(() => formatTimeStamp(time), [time]);

  return (
    <Box
      alignItems="center"
      className="refreshCounter"
      id="coinsTable"
    >
      {error ? 'Listing is currently not available. We are trying to resolve the issue.' : `Listing updates every 15 minutes, last update - ${parsedTime}.`}
    </Box>
  );
};

export default RefreshCounter;
