import React, { FC } from 'react';

import PriceText from 'components/table/PriceText';
import { TokenMarket } from 'types/TokenMarket';
import { MarketMetadataItemType } from 'types/MarketMeta';

import {
  Card, CardContent, Typography, Box
} from '@mui/material';

interface Props {
  market: TokenMarket;
  metadata: MarketMetadataItemType;
}
const ExchangeCard: FC<Props> = ({ ...props }) => {
  const { market, metadata } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Card>
        <CardContent
          style={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '16px',
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Typography
            style={{ alignItems: 'center', display: 'flex', gap: '10px' }}
          >
            <img
              src={`data:image/png;base64,${metadata.logo}`}
              alt="logo"
              height="30"
            />
            {market.market}
          </Typography>
          <PriceText price={market.price} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExchangeCard;
