import { useEffect, useState } from 'react';
import cmsAxiosInstance from 'api/cms-axios-instance';

const useFetchCms = (id: string) => {
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<any|undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true);
          const response = await cmsAxiosInstance.get(`/articles/${id}?populate=*`);
          setError(undefined);
          setData(response.data.data.attributes);
        } catch (e: any) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [id]);

  return { data, error, loading };
};

export default useFetchCms;
