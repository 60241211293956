import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import './CenteredProgress.scss';

const CenteredProgress = () => (
  <Box className="centeredProgress">
    <CircularProgress color="primary" />
  </Box>
);

export default CenteredProgress;
